
const apiEndpointTable = () => ({
    client: `${process.env.GATSBY_API_ENDPOINT}/api/client/v1`,
    foundation: `${process.env.GATSBY_API_ENDPOINT}/api/foundation/v1`,
});

const apiEndpoints = apiEndpointTable();

const settings = {
    client: {
        apiEndpoint: apiEndpoints.client,        
        loginPath: 'login',
        registerPath: 'register',
        activatePath: 'activate'
    },
    fndEndpoint: apiEndpoints.foundation,
    localStoreKey: 'teamlink-user',
    prefStoreKey: 'teamlink-app',
    clientId: '5a6a8224288b4c4bb9fe1a2140f81175',    
    name: 'teamlink',
    displayName: 'Teamlink',
    config: {
        googleApiKey: 'AIzaSyDiQEs5OlHwQ-rBzQyYWwU2yCYEM8X6R6s',
    },
};

export function getListingUrl(listingId, userId) {
    return `${process.env.GATSBY_API_ENDPOINT}/share/listing/${listingId}?referrer=${userId}`
}


export default settings;