import { observable, action, computed, autorun } from 'mobx';
import concat from 'lodash/concat'
import sortBy from 'lodash/sortBy'
import find from 'lodash/find'
import pick from 'lodash/pick'
import uniqBy from 'lodash/uniqBy'
import values from 'lodash/values'
import { format } from 'date-fns'
import { clone, includeChinese } from '@src/utils/typeCheck';
import chunk from 'lodash/chunk'
import unionBy from 'lodash/unionBy'
import trim from "lodash/trim"
import isEqual from 'lodash/isEqual';
import debounce from 'lodash/debounce';
import polygonClipping from 'polygon-clipping'
import {getDistance,getCenter,getBounds} from 'geolib'

class MapStore {
	constructor(appStore, generalInspectionStore) {
		this.appStore = appStore
		this.generalInspectionStore = generalInspectionStore
	}

	defaultRegion = (latitude, longitude) => {
		return { latitude: latitude, longitude: longitude }
	}

	@observable searchRadius = 5000
	@action setSearchRadius = value => (this.searchRadius = value)

	@observable centerCoordinate = null
	@action setCenterCoordinate = value => (this.centerCoordinate = value)

	hasOpenInspectionToday = (isProject, listing) => {
		if (isProject) {
			return false
		} else {
			if (
				listing[":openInspections"] &&
				listing[":openInspections"].length > 0
			) {
				const availableTimes =
					this.generalInspectionStore.generateOpenInspectionTimeList(
						listing[":openInspections"]
					)
				// console.log('hasOpenInspectionToday',listing,availableTimes)
				if (availableTimes.length > 0) {
					const first = availableTimes[0]
					const dateObj = new Date(first.details.ISODateString)
					const todayDateObj = new Date()
					return (
						format(dateObj, "yyyy-MM-dd") ===
						format(todayDateObj, "yyyy-MM-dd")
					)
				} else {
					return false
				}
			}
			return false
		}
	}

	getMarkersFromProperties = async (rawMapData, filterObject) => {
		if (!rawMapData) return []
		let cluster = clone(rawMapData.clusters)
		rawMapData.items
			.filter(
				e =>
					e[":address"].latitude !== null &&
					e[":address"].longitude !== null
			)
			.forEach(e => {
				const listing = e[":listing"]
				const isProject = listing.mainCategory === 100
				const targetCluster = cluster[e["$clusterKey"]]
				if (e.id === 59) {
					// console.log('id:59',targetCluster)
				}
				if (targetCluster.totalItems === 1) {
					cluster[e["$clusterKey"]] = {
						...cluster[e["$clusterKey"]],
						unifyId: `${e["$clusterKey"]} ${filterObject.listingType}`,
						rawData: [e],
						itemNumber: 1,
						addressFullTextLine: e[":address"].fullText,
						name: listing.name,
						listingId: listing.id,
						isLeasing: listing.mainCategory === 300,
						openInspection: this.hasOpenInspectionToday(
							isProject,
							listing
						),
						coordinate: this.defaultRegion(
							targetCluster.location.latitude,
							targetCluster.location.longitude
						),
						isProject: isProject,
						thumbnail: isProject ? targetCluster.logo || "" : "",
						showTextForPrice: listing?.showTextForPrice,
						price: isProject
							? 0
							: listing[":prices"]["LIST"]
							? listing[":prices"]["LIST"].amount
							: 0,
						filterObject: filterObject,
					}
				} else {
					cluster[e["$clusterKey"]] = {
						...cluster[e["$clusterKey"]],
						unifyId: `${e["$clusterKey"]} ${filterObject.listingType}`,
						rawData: targetCluster.rawData
							? [...targetCluster.rawData, e]
							: [e],
						coordinate: this.defaultRegion(
							targetCluster.location.latitude,
							targetCluster.location.longitude
						),
						isProject: false,
						listingId: null,
						isLeasing: listing.mainCategory === 300,
						thumbnail: "",
						itemNumber: targetCluster.totalItems,
						showTextForPrice: listing?.showTextForPrice,
						price: listing[":prices"]["LIST"]
							? listing[":prices"]["LIST"].amount
							: 0,
						filterObject: filterObject,
					}
				}
			})
		// const data = values(cluster)
		const geoJSON = values(cluster).map(item => ({
			type: "Feature",
			properties: { cluster: false, data: item },
			geometry: {
				type: "Point",
				coordinates: [
					item.coordinate.longitude,
					item.coordinate.latitude,
				],
			},
		}))
		return geoJSON
	}

	filterMarkerByListingId = (markers, listingIds) => {
		// console.log(markers,listingIds)
		let newMarkers = []
		markers.forEach(marker => {
			if (marker.properties.data.itemNumber === 1) {
				if (listingIds.includes(marker.properties.data.listingId)) {
					newMarkers.push(marker)
				}
			} else {
				const filteredRawData = marker.properties.data.rawData.filter(
					d => listingIds.includes(d.listing)
				)
				if (filteredRawData.length === 0) {
					return
				} else {
					let newData = clone(marker)
					newData.properties.data.rawData = filteredRawData
					newData.properties.data.itemNumber = filteredRawData.length
					newMarkers.push(newData)
				}
			}
		})
		return newMarkers
	}

	createMarkerFromData = rawData => {
		const listing = rawData[":listing"]
		const address = rawData[":address"]
		const isProject = listing.mainCategory === 100
		return {
			unifyId: `temp ${listing.id}`,
			rawData: [rawData],
			itemNumber: 1,
			addressFullTextLine: rawData[":address"].fullText,
			name: listing.name,
			listingId: listing.id,
			isLeasing: listing.mainCategory === 300,
			openInspection: this.hasOpenInspectionToday(isProject, listing),
			coordinate: this.defaultRegion(address.latitude, address.longitude),
			isProject: isProject,
			thumbnail: isProject ? rawData.logo || "" : "",
			showTextForPrice: listing?.showTextForPrice,
			price: isProject
				? 0
				: listing[":prices"]["LIST"]
				? listing[":prices"]["LIST"].amount
				: 0,
			filterObject: {},
		}
	}

	unionMaker = (oldm, newm) => {
		if (oldm.length === 0) {
			return newm
		}
		if (newm.length === 0) {
			return []
		}
		return unionBy(
			oldm.filter(o => isEqual(o.filterObject, newm[0].filterObject)),
			newm,
			e => e.unifyId
		)
	}

	nearBySearch_ = async (coordinate, typeKey, radius = 5000) => {
		const res = await this.appStore.clientApi.getNearBySchool_(
			coordinate.latitude,
			coordinate.longitude,
			radius,
			typeKey
		)
		return res
	}

	getSchoolInfo_ = async coordinate => {
		const typeKeys = ["primary_school", "secondary_school", "university"]
		const res = await Promise.all(
			typeKeys.map(type => this.nearBySearch_(coordinate, type, 5000))
		)
		let newSchoolInfo = {
			All: [],
			Primary: [],
			Secondary: [],
			University: [],
		}
		let key = null
		res.forEach(({ results }, index) => {
			switch (index) {
				case 0:
					key = "Primary"
					break
				case 1:
					key = "Secondary"
					break
				case 2:
					key = "University"
					break
			}

			results.forEach(e => {
				if (!includeChinese(e.name)) {
					newSchoolInfo[key].push({
						name: e.name,
						ageRange: "",
						type: key,
						social: "",
						distance: getDistance(coordinate, {
							latitude: e.geometry.location.lat,
							longitude: e.geometry.location.lng,
						}),
						rank: "",
					})
				}
			})

			newSchoolInfo[key] = sortBy(newSchoolInfo[key], ["distance"])
			newSchoolInfo[key] = uniqBy(newSchoolInfo[key], "name")
		})

		newSchoolInfo.All = uniqBy(
			sortBy(
				concat(
					newSchoolInfo.Primary,
					newSchoolInfo.Secondary,
					newSchoolInfo.University
				),
				["distance"]
			),
			"name"
		)
		return newSchoolInfo
	}

	getNearby = async (coordinate, type) => {
		const { results } = await this.nearBySearch_(coordinate, type, 3000)
		const trainStations = []
		results.forEach(e => {
			if (!includeChinese(e.name)) {
				trainStations.push({
					name: e.name,
					types: e.types,
					distance: getDistance(coordinate, {
						latitude: e.geometry.location.lat,
						longitude: e.geometry.location.lng,
					}),
				})
			}
		})

		return trainStations
	}

	processDrawPolygons = (polygonCoordinates)=>{
        const res = polygonClipping.xor([polygonCoordinates.map(item=>([item.latitude,item.longitude]))])
        const multiPolygons = res.map(polygons=>{
            const polygon = polygons[0]
            const coordinatePolygon = polygon.map(coordinate=>({latitude:coordinate[0],longitude:coordinate[1]}))
            let compressedPolygon = []
            if(coordinatePolygon.length>15){
                const groupSize = coordinatePolygon.length<15?15:coordinatePolygon.length>15 && coordinatePolygon.length<30?2:3
                compressedPolygon = chunk(coordinatePolygon,groupSize).map(chu => chu[0])
            }else{
                compressedPolygon = [...coordinatePolygon]
            }
            return compressedPolygon
        })
        return multiPolygons
    }

	@observable searchSession = null
	@action setSearchSession = searchSession =>
		(this.searchSession = searchSession)

	@observable searchPredictions = []
	@action setSearchPredictions = value => (this.searchPredictions = value)

	clearSuggestions = () => {
		this.setSearchPredictions([])
	}

	debouncePlaceAutoComplete = debounce(async str => {
		const res = await this.appStore.clientApi.placeAutoComplete_(
			str,
			this.searchSession
		)
		// console.log(res)
		this.setSearchSession(res.session)
		this.setSearchPredictions(res.predictions)
		return res.predictions
	}, 300)

	// points2LatLng=(points, map,maps)=> {
	//     const topRight = map.getProjection().fromLatLngToPoint(map.getBounds().getNorthEast());
	//     const bottomLeft = map.getProjection().fromLatLngToPoint(map.getBounds().getSouthWest());
	//     const scale = Math.pow(2, map.getZoom());
	//     let res = []
	//     console.log(topRight,bottomLeft,scale)
	//     points.forEach(point=>{
	//         const worldPoint = new maps.Point(point.x / scale + bottomLeft.x, point.y / scale + topRight.y);
	//         res.push(map.getProjection().fromPointToLatLng(worldPoint))
	//     })
	//     return res
	// }

	// drawPointsOnMap = (paths,map,maps)=>{
	//     const pathsPoints = paths[0].paths
	//     console.log('call drawPointsOnMap',pathsPoints,map,maps)
	//     const latLongs = this.points2LatLng(pathsPoints,map,maps).map(item=>({lat:item.lat(),lng:item.lng()}))
	//     console.table(latLongs)
	//     // Send Api request
	//     // onDrawEnd(latLongs)
	//     const aera = new maps.Polygon({
	//         paths: latLongs,
	//         strokeColor: "black",
	//         strokeOpacity: 0.8,
	//         strokeWeight: 2,
	//         fillColor: "black",
	//         fillOpacity: 0.35,
	//     });
	//     aera.setMap(map);
	//     return latLongs
	// }

	// debounceCheckDrawIsEndAndProcessCb=debounce((paths,cb)=>{
	//     if(paths && paths[0].endTimestamp!==0){
	//         cb()
	//     }
	//     return false
	// },300)

	@observable searchedMapCenter = {
		latitude: null,
		longitude: null,
	}
	@action setSearchedMapCenter = value => (this.searchedMapCenter = value)

	getAndNavigateToCenter_ = async searchLine => {
		if (trim(searchLine) === "") {
			return
		}
		const result = await this.appStore.clientApi.getGeocoding_(
			trim(searchLine)
		)
		console.log("getAndNavigateToCenter_", result)
		let coordinate = {
			latitude: null,
			longitude: null,
		}
		if (result) {
			const cor = result.geometry.location
			coordinate = {
				latitude: cor.lat,
				longitude: cor.lng,
			}
		}
		this.setSearchedMapCenter(coordinate)
		return coordinate
	}
}

export default MapStore;